<nav class="bigscreen">
  <div id="logo" routerLink="home">
    <img src="../assets/images/Logo-T2-sigma.svg" />
  </div>
  <div id="menu">
    <div class="menu-item" (click)="scroll('banner')">HOME</div>
    <div class="menu-item" (click)="scroll('info')">WINKEL</div>
    <div class="menu-item" (click)="scroll('bestellen')">BESTELLEN</div>
    <div class="menu-item" (click)="goToWebshop()">WEBSHOP</div>
    <div class="menu-item" (click)="goToGoedecor()">RAAMDECORATIE</div>
  </div>
</nav>
<nav class="smallscreen">
  <div id="topbar">
    <div id="logo" routerLink="home">
      <div id="logo"><img src="../assets/images/Logo-T2-sigma.svg" /></div>
    </div>
    <div id="menu" (click)="menuToggle()"><fa-icon [icon]="['fas', 'bars']"></fa-icon></div>
  </div>
  <div id="menulist" [class.show]="menulist==true" [class.hide]="menulist==false">
    <div class="menu-item" (click)="scroll('banner')">HOME</div>
    <div class="menu-item" (click)="scroll('info')">WINKELS</div>
    <div class="menu-item" (click)="scroll('bestellen')">BESTELLEN</div>
    <div class="menu-item" (click)="goToWebshop()">WEBSHOP</div>
    <div class="menu-item" (click)="goToGoedecor()">RAAMDECORATIE</div>
  </div>
</nav>
<div id="content">
  <div id="banner">
    <div id="banner-logo" class="bigscreen">
      <img src="../assets/images/Logo-T2-sigma.svg" />
    </div>
    <div
      id="banner-img"
      style="background-image: url('../assets/images/sigma-shop-binnen.jpg')"
    ></div>
  </div>
  <div id="info">
    <div id="info-text">
      <h1>INFO OVER SIGMA-SHOP</h1>
      <div>
        <p>
          In onze sigma shop kunnen klanten terecht voor alle verf-, bijhorende
          producten, behang en technisch of kleuradvies.
        </p>
        <p>
          Met een zeer persoonlijke aanpak zullen onze gespecialiseerde
          medewerkers elke klant begeleiden doorheen het volledige proces, om zo
          het gewenste resultaat te bekomen.
        </p>
      </div>
    </div>
    <div class="gegevens">
      <div class="winkel1">
        <div class="address">
          <h1>SIGMASHOP</h1>
          <div class="straat">Turfputten 1</div>
          <div class="gemeente">2250, Olen</div>
          <div class="mail">tist.peeters@sigmashop-olen.be</div>
          <div class="tel">014/26.31.01</div>
        </div>
        <div class="uren">
          <h2>OPENINGSUREN</h2>
          <div class="uur">
            <div>Maandag</div>
            <div>7u - 12u 13u - 18u</div>
          </div>
          <div class="uur">
            <div>Dinsdag</div>
            <div>7u - 12u 13u - 18u</div>
          </div>
          <div class="uur">
            <div>Woensdag</div>
            <div>7u - 12u 13u - 18u</div>
          </div>
          <div class="uur">
            <div>Donderdag</div>
            <div>7u - 12u 13u - 18u</div>
          </div>
          <div class="uur">
            <div>Vrijdag</div>
            <div>7u - 12u 13u - 17u</div>
          </div>
          <div class="uur">
            <div>Zaterdag</div>
            <div>8u - 12u</div>
          </div>
          <div class="uur">
            <div>Zondag</div>
            <div>gesloten</div>
          </div>
          <!-- <div id="kersturen">
            <h2>Afwijkende uren</h2>
            <div class="uur">
              <div>01/04 - 06/04</div>
              <div>gesloten</div>
            </div>
          </div> -->
        </div>
        <div class="map">
          <div class="mapouter">
            <div class="gmap_canvas">
              <iframe
                width="100%"
                height="100%"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBrVMTHlPCNeGzk_DNFJPtYehE-zFgzAs0
            &q=Sigmashop - Goe-Decor - Olen
            &zoom=17"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="winkel2">
        <div class="address">
          <h1>WINKEL VOSSELAAR</h1>
          <div class="straat">Antwerpsesteenweg 90</div>
          <div class="gemeente">2350, Vosselaar</div>
          <div class="mail">winkel-vosselaar@groeptom.be</div>
          <div class="tel">014/61.23.17</div>
        </div>
        <div class="uren">
          <h2>OPENINGSUREN</h2>
          <div class="uur">
            <div>Maandag</div>
            <div>8u - 12u 13u - 18u</div>
          </div>
          <div class="uur">
            <div>Dinsdag</div>
            <div>8u - 12u 13u - 18u</div>
          </div>
          <div class="uur">
            <div>Woensdag</div>
            <div>8u - 12u 13u - 18u</div>
          </div>
          <div class="uur">
            <div>Donderdag</div>
            <div>8u - 12u 13u - 18u</div>
          </div>
          <div class="uur">
            <div>Vrijdag</div>
            <div>8u - 12u 13u - 17u</div>
          </div>
          <div class="uur">
            <div>Zaterdag</div>
            <div>8u - 13u</div>
          </div>
          <div class="uur">
            <div>Zondag</div>
            <div>gesloten</div>
          </div>
          <div id="kersturen">
            <h2>Afwijkende uren</h2>
            <div class="uur">
              <div>01/04 - 06/04</div>
              <div>gesloten</div>
            </div>
          </div>
        </div>
        <div class="map">
          <div class="mapouter">
            <div class="gmap_canvas">
              <iframe
                width="100%"
                height="100%"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBrVMTHlPCNeGzk_DNFJPtYehE-zFgzAs0
            &q=Goe-Decor - Sigmashop - Vosselaar
            &zoom=16"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  <div class="divider"></div>
  <div id="bestellen">
    <div
      class="bestel-card"
      style="
        background-image: url('../assets/images/westerlo_winkel.jpg');
      "
    >
      <div class="bestel-text">
        <h2>SIGMA-SHOP</h2>
        <h3>U kan bij ons artikelen bestellen voor afhaling via:</h3>
        <a href="mailto:tist.peeters@sigmashop-olen.be" class="icon-line"
          ><fa-icon [icon]="['fas', 'envelope']"></fa-icon>
          <p>tist.peeters@sigmashop-olen.be</p></a
        >
        <a href="tel:014263101" class="icon-line"
          ><fa-icon [icon]="['fas', 'phone']"></fa-icon>
          <p>014/26.31.01</p></a>
      </div>
    </div>
    <!-- <div
      class="bestel-card"
      style="
        background-image: url('../assets/images/vosselaar_winkel.jpg');
      "
    >
      <div class="bestel-text">
        <h2>SIGMA-SHOP VOSSELAAR</h2>
        <h3>U kan bij ons artikelen bestellen voor afhaling via:</h3>
        <a href="mailto:winkel-vosselaar@groeptom.be" class="icon-line"
        ><fa-icon [icon]="['fas', 'envelope']"></fa-icon>
        <p>winkel-vosselaar@groeptom.be</p></a
      >
      <a href="tel:014612317" class="icon-line"
        ><fa-icon [icon]="['fas', 'phone']"></fa-icon>
        <p>014/61.23.17</p></a
      >
      </div>
    </div> -->
    <div
      class="bestel-card"
      style="
        background-image: url('../assets/images/DSC_4084.jpg');
      "
    >
      <div class="bestel-text">
        <h2>VERF-ONLINE.BE</h2>
        <h3>Fan van online bestellen? Bekijk hier onze webshop!</h3>
        <a href="https://www.verf-online.be" target="_blank" class="icon-line">
          <fa-icon [icon]="['fas', 'shopping-cart']"></fa-icon>
          <p>www.verf-online.be</p>
        </a>
      </div>
    </div>
  </div>
  <!-- <div class="divider"></div>
  <div id="groeptom">
    <h1>SIGMA-SHOP IS DEEL VAN GROEP T.O.M</h1>
    <div id="logo" (click)="goToUrl('https://www.groeptom.be')">
      <img src="../assets/images/logo_tom.png" />
    </div>
    <div id="buttons">
      <div
        class="button"
        id="tom"
        (click)="goToUrl('https://www.groeptom.be/tom')"
      >
        <img
          class="button-logo"
          src="../assets/images/totaalprojecten-icon.svg"
        />
        <div class="button-naam">TOTAAL PROJECTEN OP MAAT</div>
      </div>
      <div
        class="button"
        id="kom"
        (click)="goToUrl('https://www.groeptom.be/kom')"
      >
        <img class="button-logo" src="../assets/images/kleuropmaat-icon.svg" />
        <div class="button-naam">KLEUR OP MAAT</div>
      </div>
      <div
        class="button"
        id="mom"
        (click)="goToUrl('https://www.groeptom.be/mom')"
      >
        <img class="button-logo" src="../assets/images/meubelopmaat-icon.svg" />
        <div class="button-naam">MEUBEL OP MAAT</div>
      </div>
      <div
        class="button"
        id="sigma"
        (click)="goToUrl('https://www.goedecor.be/')"
      >
        <img class="button-logo" src="../assets/images/goedecor-icon.svg" />
        <div class="button-naam">GOE-DECOR RAAMDECORATIE</div>
      </div>
    </div>
  </div> -->
</div>
<footer>
  <div id="f-logo">
    <img src="../assets/images/Logo-T2-sigma.svg" />
  </div>

  <!-- <div class="">
    <div class="f-menu-item">Groep T.O.M. Olen</div>
    <div class="f-menu-item">
      <div
        class="hover"
        (click)="goToSocial('https://www.facebook.com/GROEPTOM')"
      >
        <fa-icon [icon]="['fab', 'facebook']"></fa-icon>
      </div>
      <div
        class="hover"
        (click)="goToSocial('https://www.instagram.com/groep_t.o.m')"
      >
        <fa-icon [icon]="['fab', 'instagram']"></fa-icon>
      </div>
    </div>
  </div> -->
  <div class="">
    <!-- <div class="f-menu-item">T2 verf en raamdecoratie</div> -->
    <div class="f-menu-item">
      <!-- <div
        class="hover"
        (click)="
          goToSocial(
            'https://www.facebook.com/Goe-Decor-Raamdecoratie-111064657418002'
          )
        "
      >
        <fa-icon [icon]="['fab', 'facebook']"></fa-icon>
      </div> -->
      <div
        class="hover"
        (click)="goToSocial('https://www.instagram.com/t2_verf_raamdecoratie/')"
      >
        <fa-icon [icon]="['fab', 'instagram']"></fa-icon>
      </div>
    </div>
  </div>
  <div id="webshop" class="f-menu-item hover">
    <div (click)="goToWebshop()" class="verfonline">
      <fa-icon [icon]="['fas', 'shopping-cart']"></fa-icon>www.verf-online.be
    </div>
  </div>
</footer>
