import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faBars, faEnvelope, faMapMarkerAlt, faMarker, faPhone, faShoppingBag, faShoppingCart } from '@fortawesome/free-solid-svg-icons';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(library: FaIconLibrary) {
    library.addIcons(faFacebook);
    library.addIcons(faInstagram);
    library.addIcons(faPhone);
    library.addIcons(faEnvelope);
    library.addIcons(faMapMarkerAlt);
    library.addIcons(faShoppingCart);
    library.addIcons(faBars)
  }
 }
