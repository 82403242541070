import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'sigma-verfwinkel';

  ngOnInit(): void {
    AOS.init();
  }
  menulist = false;
  menuToggle(){
    if (this.menulist == false) {
      this.menulist = true;
    } else {
      this.menulist = false;
    }
  }
  scroll(id) {
    let el = document.getElementById(id);
    el.scrollIntoView({behavior:'smooth', block: 'center'});
  }

  goToWebshop(){
    window.open('https://www.verf-online.be', "_blank");
  }
  goToSocial(url:string) {
    window.open(url,"_blank");
  }
  goToUrl(url:string){
    window.open(url,"_blank")
  }
  goToGroepTom(){
    window.open('https://www.groeptom.be', "_blank");
  }
  goToGoedecor(){
    window.open('https://www.goedecor.be', "_blank");
  }



}
